*
{
    margin: 0;
    padding: 0;
    font-family: 'Nunito', Arial, sans-serif;
}

html,
body
{
    height: 100vh;
    font-family: 'Poppins';
    background:#555;
}

.webgl
{
    position: fixed;
    top: 0;
    right: 0;
    outline: none;
}

.container{
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    color:white;
    margin:16em 8em;
}

h1{
    font-size:4rem;
}

p{
    font-size:1.29rem;
    width:65%;
    color: rgb(181, 181, 201);
}

button{
    font-size:1.3rem;
    padding:.5rem 1rem;
    margin-top:3em;
    background-color:rgb(0,113,255);
    border:none;
    color:white;
    font-weight:bold;
    text-transform:uppercase;
}
footer{
    position:fixed;
    bottom:10px;
    right:6px;
    z-index: 9999999;
    color: white;
}